body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: whitesmoke;
  background-color: #333;
}

h1, h2, h3 {
  font-weight: bold;
  text-align: center;
}

h1 {
  font-size: 1.5em;
  text-transform: uppercase;
}

h2 {
  font-size: 1.3em;
  margin-bottom: 1em;
}

h3 {
  font-size: 1.2em;
  margin-bottom: 1em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  background-color: whitesmoke;
  color: #333;
  padding: 3em;
  border-radius: 10px;
  margin-top: 3em;
  margin-bottom: 3em;
}

.btn-primary {
  background-color: #FF5F0A;
  border-color: #d44d0f;
}

.btn-primary:hover, .btn-primary:active, .btn-primary:focus {
  background-color: #d44d0f !important;
  border-color: #d44d0f !important;
}

.alert {
  margin-top: 1em;
}

.aboutContactInfo {
  font-size: 0.9em;
  color: #6c757d;
}
#backButton {
  position: absolute;
  margin-top:-5px;
}

.withVMargin {
  margin-top: 16px;
  margin-bottom: 16px;
}

.cursorPointer {
  cursor: pointer;
}

.unavailable {
  color: grey;
}

.text-monospace {
  font-size: 0.7em;
}
.btn-primary.disabled, .btn-primary:disabled , .btn-primary.disabled:hover, .btn-primary:disabled:hover{
  background: #999 !important;
  border-color:#666 !important;
}
#updateList tr td:first-child {
  border-left:2px solid #aaa  ;
}
#updateList tr.selected  td:first-child {
  border-left:2px solid #FF5F0A;
}
#updateList tr.selected {
  background: #FF5F0A33;
}

.installMessage {
  white-space: pre-wrap;
}

.installMessages {
  padding-left: 20px;
}